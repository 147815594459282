import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/organization-review-modal.json';

export default class CreateOrganizationReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anonymous: false,
      errorMessages: [],
      isConfirmationModalOpen: false,
      position: 99,
        rating: 1,
      total_review: '',
        invest_style: '',
        interview_style: '',
        support_frequency: '',
      value_to_focus: [],
      interview_preparation: [],
      interview_preparation_other: '',
      recommend_characteristic: [],
      support_received: [],
      support_received_other: '',
      support_received_other_entered: false,
      interview_preparation_other_entered: false,
    };

    this._showConfirmationModal = this._showConfirmationModal.bind(this);
    this._closeConfirmationModal = this._closeConfirmationModal.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onCheckBoxChange = this._onCheckBoxChange.bind(this);
    this._onCheckBoxGroupChange = this._onCheckBoxGroupChange.bind(this);
    this._submit = this._submit.bind(this);
  }

  _showConfirmationModal(){
    this.setState({
      isConfirmationModalOpen: true
    });
  }

  _closeConfirmationModal(){
    this.setState({
      isConfirmationModalOpen: false
    });
  }

  _onFormChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  _onCheckBoxChange(e) {
    this.setState({[e.target.name]: e.target.value === 'on'});
  }

  _onCheckBoxGroupChange(e) {
    const arr = this.state[e.target.name];
    const key = e.target.value;

    if (arr.indexOf(key) === -1) {
      arr.push(key);
    } else {
      arr.splice(arr.indexOf(key), 1);
    }

    this.setState({
      [e.target.name]: arr,
    });
  }

  _submit(){
    const id = this.props.id;
    const params ={
      anonymous: this.state.anonymous,
      rating: this.state.rating,
      position: this.state.position,
      total_review: this.state.total_review,
      good_point: '', // For Compatibility Purpose Only
      bad_point: '', // For Compatibility Purpose Only
      invest_style: this.state.invest_style,
      interview_style: this.state.interview_style,
      support_frequency: this.state.support_frequency,
      value_to_focus: this.state.value_to_focus,
      interview_preparation: this.state.interview_preparation,
      interview_preparation_other: '',
      recommend_characteristic: this.state.recommend_characteristic,
      support_received: this.state.support_received,
      support_received_other: '',
    };

    if (this.state.support_received_other_entered) {
      params.support_received_other = this.state.support_received_other;
    }

    if (this.state.interview_preparation_other_entered) {
      params.interview_preparation_other = this.state.interview_preparation_other;
    }

    let apiCall = this.props.organization ? DatavaseApi.postOrganizationReviews(id, params)
        : DatavaseApi.postPersonReviews(id, params);
    apiCall.then(_res => {
        window.location.href = `/action_done/review?goto=${window.location.href}/review/${_res.data.id}`;
    }).catch((err) => {
      this.setState({
        isConfirmationModalOpen: false,
        errorMessages: Object.values(err.response.data)
      });
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
        <div>
            <Modal
                show={this.state.isConfirmationModalOpen}
                onHide={this._closeConfirmationModal}
                style={{padding: "5px"}}
            >
                <Modal.Body>
                    <p style={{textAlign: "center", fontSize: "16px"}}>
                        {lang.areYouSure}
                    </p>
                    <ControlLabel>{lang.rating}</ControlLabel>
                    <p>{this.state.rating}</p>
                    <ControlLabel>{lang.reason}</ControlLabel>
                    <p>{this.state.total_review}</p>
                    <div style={{width: "50%", margin: "40px auto 0px"}}>
                        <Button
                            className="cancel-button"
                            onClick={this._closeConfirmationModal}
                        >
                            編集する
                        </Button>
                        <Button
                            className="submit-button"
                            bsStyle="success"
                            onClick={this._submit}
                        >
                            投稿する
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={this.props.show}
                onHide={this.props.onCancel}
            >
                <Modal.Body>
                    <div style={{color: 'red', fontWeight: 'bold', margin: '10px'}}>
                        {this.state.errorMessages.map((err, i) => <div key={i}>{err}</div>)}
                    </div>
                    <div style={{margin: '10px', padding: '10px', fontWeight: 'bold', border: "solid 1px gray"}}>
                        datavase.ioへ口コミいただく皆様へ<br/>
                        <br/>
                        datavase.ioは資本主義社会に光を照らすためのサービスです。<br/>
                        皆様の一つの評価によって起業家、投資家、事業会社のあらゆる意思決定を大きく変える可能性があります。
                        投稿形式は実名・匿名をお選びいただけますが、ファクトに基づいた評価をお願い致します。<br/><br/>
                        皆様の愛のある投稿によって資本主義社会がより明るい未来へと誘われることを心よりお祈り致します。<br/>
                        <br/>
                        datavase.io team より<br/>
                    </div>
                    <FormGroup>
                        <ControlLabel style={{marginTop: "0.5cm"}}> {lang.submitMode} </ControlLabel>
                        <div>
                            <Button bsStyle={!this.state.anonymous ? "primary" : "default"} name="anonymous"
                                    onClick={() => {
                                        this.setState({anonymous: false})
                                    }}> {lang.username} </Button>
                            <Button bsStyle={this.state.anonymous ? "primary" : "default"} name="anonymous"
                                    onClick={() => {
                                        this.setState({anonymous: true})
                                    }}> {lang.anonymous} </Button>
                        </div>
                        <br/>
                        <ControlLabel style={{marginTop: "0.3cm"}}>{lang.rating}</ControlLabel>
                        <div>
                            <Button bsStyle={this.state.rating === 1 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({rating: 1})
                                    }}>1</Button>
                            <Button bsStyle={this.state.rating === 2 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({rating: 2})
                                    }}>2</Button>
                            <Button bsStyle={this.state.rating === 3 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({rating: 3})
                                    }}>3</Button>
                            <Button bsStyle={this.state.rating === 4 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({rating: 4})
                                    }}>4</Button>
                            <Button bsStyle={this.state.rating === 5 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({rating: 5})
                                    }}>5</Button>
                        </div>
                        <ControlLabel style={{marginTop: "0.8cm"}}>自分との関係性</ControlLabel>
                        <div>
                            <Button bsStyle={this.state.position === 1 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 1})
                                    }}>出資者</Button>
                            <Button bsStyle={this.state.position === 2 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 2})
                                    }}>出資先</Button>
                            <Button bsStyle={this.state.position === 3 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 3})
                                    }}>ユーザー</Button>
                            <Button bsStyle={this.state.position === 4 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 4})
                                    }}>パートナー</Button>
                            <Button bsStyle={this.state.position === 5 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 5})
                                    }}>求職者</Button>
                            <Button bsStyle={this.state.position === 6 ? "primary" : "default"} name="support_frequency"
                                    onClick={() => {
                                        this.setState({position: 6})
                                    }}>チーム</Button>
                            <Button bsStyle={this.state.position === 99 ? "primary" : "default"}
                                    name="support_frequency" onClick={() => {
                                this.setState({position: 99})
                            }}>その他</Button>
                        </div>
                        <ControlLabel> {lang.reason} </ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            type="text"
                            name="total_review"
                            onChange={this._onFormChange}
                        />
                        <div style={{width: "50%", margin: "40px auto 0px"}}>
                            <Button
                                className="cancel-button"
                                onClick={this.props.onCancel}
                            >
                                キャンセルする
                            </Button>
                            <Button
                                type="submit"
                                className="submit-button"
                                bsStyle="success"
                                onClick={this._showConfirmationModal}
                            >
                                投稿する
                            </Button>
                        </div>
                    </FormGroup>
                </Modal.Body>
            </Modal>
        </div>
    );
  }
}
