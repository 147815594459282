import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../css/error-page.css';

export default class RedirectToLandingPage extends Component {
  render() {
    window.location.href = "/landing/";
    return (
      <div>
        <p>ランディングページへ転送中です</p>
        <p>数秒待って転送されない場合、<Link to="/landing/" onlyActiveOnIndex>このリンクをクリックしてランディングページへ移動してください</Link>。</p>
      </div>
    );
  }
}
