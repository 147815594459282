import React, { Component } from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import BlockSpinner                 from './partials/block-spinner';
import ExitsPanel                   from './partials/exits-panel';
import NewsArticlesByResourcePanel  from './partials/news-articles-by-resource-panel';
import PersonAbstractPanel          from './partials/person-abstract-panel';
import PersonDataGridPanel          from './partials/person-data-grid-panel';
import PersonHistoryDetailsPanel    from './partials/person-history-details-panel';
import PersonGraphsPanel            from './partials/person-graphs-panel';
import ContactSocialPanel           from './partials/contact-social-panel';
import TimelineEventsPanel          from './partials/timeline-events-panel';
import ContributionsPanel           from './partials/contributions-panel';
import TwitterTimelinePanel         from './partials/twitter-timeline-panel';
import PersonReviewListsPanel       from './partials/person-review-lists-panel';

import * as DatavaseApi             from './datavase-api';
import i18n                         from '../i18n/person-details-pane.json'
import '../css/details-pane.css';

class PersonDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      contributions: null,
      hasLoaded: false,
      id: null,
    };

    this._onTabSelect = this._onTabSelect.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchPersonWithRelatedResources(id)
    .then(res => {
      this.setState({
        resources: res.data,
        hasLoaded: true,
        id: res.data.person.id,
      })
    }).catch(res => {
      console.error(res);
    })
  }

  _onTabSelect(key) {
    if(key === "contributions") {
      if(!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('people', id)
        .then(res => {
          this.setState({contributions: res.data});
        })
        .catch(res => {
          console.error(res);
        });
      }
    }
  }

  render () {
    if(!this.state.hasLoaded) {
      return(<BlockSpinner visible={true}/>);
    }

    const langCode  = DatavaseApi.getLangCode();
    const lang      = i18n[langCode];

    const {resources, id, contributions} = this.state;
    const person                           = resources.person;
    const newsArticles                     = resources.news_articles;

    return (
      <div id="details-page-contents">
        <Col sm={12} md={12} lg={12}>
          <PersonAbstractPanel
            person={person}
            profileImage={person.profile_image_url}
            id={id}
          />
        </Col>
        <Tabs
          id="details-main-tabs"
          className="jumbo-tabs"
          onSelect={this._onTabSelect}
        >
          <Tab
            eventKey="main"
            title={lang.detailTabTitle}
            className="clearfix"
          >
            <Col sm={12} md={8} lg={8}>
              {
                newsArticles.length !== 0 ?
                <NewsArticlesByResourcePanel
                  newsArticles={newsArticles}
                />
                : null
              }
              <PersonDataGridPanel
                person={person}
                exits={resources.exits}
              />
              <PersonGraphsPanel
                person={person}
                investments={resources.investments}
              />
              <PersonHistoryDetailsPanel
                investments={resources.investments}
              />
              {
                resources.exits.ipos.length !== 0 ||
                resources.exits.acquisitions.length !== 0 ?
                <ExitsPanel exits={resources.exits} />
                : null
              }
              {
                resources.social_media_list.twitter_id ?
                <TwitterTimelinePanel
                  lang={lang}
                  screenName={resources.social_media_list.twitter_id}
                />
                : null
              }
            </Col>
            <Col sm={12} md={4} lg={4}>
              <ContactSocialPanel
                resource={person}
                resourceType={'people'}
                resourceId={this.state.id}
                websiteUrl={person.url}
                socialMediaList={resources.social_media_list}
                url={window.location.href}
                title={DatavaseApi.extractAndFormatPersonName(person, langCode)}
              />
            </Col>
          </Tab>
          <Tab
            eventKey="review"
            title={lang.review}
            className="clearfix"
          >
            <Col sm={12} md={8} lg={8}>
              <PersonReviewListsPanel
                id={id}
                openReviewModal={this.openReviewModal}
              />
            </Col>
          </Tab>
          <Tab
            eventKey="timelineHistory"
            title={lang.timelineEventsTitle}
            className="clearfix"
          >
            <Col sm={12} md={12} lg={12}>
              <TimelineEventsPanel
                timelineEvents={resources.timeline_events}
              />
            </Col>
          </Tab>
          <Tab
            eventKey="contributions"
            title={lang.editLogTabTitle}
            className="clearfix"
          >
            <Col sm={12} md={12} lg={12}>
              <ContributionsPanel
                subjectUrl={person.url}
                subjectId={id}
                contributions={contributions}
              />
            </Col>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(PersonDetailsPane);
