// 記事パネル
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { prettifyDatetime } from '../datavase-api';
import styled from "styled-components";
import color from '../styles/colors.js';

import articleEyeCatching from '../../assets/datavase_article_eyecatching.png';
import Meta from "../atoms/mini-description.js";


export default class ArticlePreviewPanel extends Component {
  _handleTag(tag) {
    const tagsHandler = this.props.tagsHandler;
    tagsHandler([tag]);
  }

  _tagsView() {
    const tags = this.props.article.tags;
    return tags.map(tag => {
      return (
        <CardTag key={tag} onClick={() => this._handleTag(tag)}>
          {tag}
        </CardTag>
      )
    });
  }

  render() {
    const article = this.props.article;
    const id = article.id;
    const title = article.title;

    const abstract = article.abstract.substring(0, 60) + "...";
    const imageSource = article.image || articleEyeCatching;
    const datetime = prettifyDatetime(article.published_at);
    const writer_name = article.author.writer_name || 'Datavase Editors';

    return (
      <Panel>
        <ThambWrap>
          <Link to={`/articles/${id}`}>
            <ThambImg alt={title} src={imageSource} />
          </Link>
        </ThambWrap>
        <TextArea>
          <TextTop>
            <Link to={`/articles/${id}`}>
              <ArticleTitle>{title}</ArticleTitle>
            </Link>
            <Meta text={`By ${writer_name}`} />
          </TextTop>
          <TextBottom>
            <TextContent>
              {abstract}
            </TextContent>
            {this._tagsView()}
          </TextBottom>
        </TextArea>
      </Panel>
    );
  }
}

const Panel = styled.div`
  background: #FFF;
  border-radius: 8px;
  overflow: hidden;
`

const ThambWrap = styled.p`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
  margin: 0;
`

const ThambImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
`

const TextArea = styled.div`
  margin: 0;
  padding: 8px 16px;
`

const TextTop = styled.div`
  margin: 0;
  padding: 0 2% 8px;
  border-bottom: 1px solid ${color.black12};
`

const TextBottom = styled.div`
  margin: 0;
  padding: 8px 2%;
`

const ArticleTitle = styled.h1`
  margin: 0 0 8px;
  color: ${color.black87};
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 24px;
  height: 48px;
  overflow: hidden;
`

const TextContent = styled.p`
  margin: 0 0 8px;
  height: 40px;
  color: ${color.black54};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
`

const CardTag = styled.button`
  padding: 4px 8px;
  border-radius: 4px;
  background: ${color.accent};
  color: #FFF;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0 8px 0 0;
`
