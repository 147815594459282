// topページ
import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import media from "styled-media-query";
// 多分これが最新の記事リスト
import AccessRankingPanel from '../organisms/access-ranking-panel.js';
import DashboardArticlePanel from '../molecules/dashboard-article-panel.js';
import DashboardQuestionPanel from '../molecules/dashboard-question-panel.js';
import SidePanel from '../organisms/side-panel.js';
import BlockSpinner from '../partials/block-spinner';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/dashboard-pane.json'
import color from '../styles/colors.js';
import MetaTags from 'react-meta-tags';
import articleEyeCatching from '../../assets/ogp-campaign-2019fall.png';
import AdsSideBar from '../organisms/ads-sidebar';
import AdsSideBarMobile from '../organisms/ads-sidebar-mobile';


export default class DashboardPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      questions: [],
      resources: {},
      hasLoaded: false,
    };

    this._fetchData = this._fetchData.bind(this);
    this._fetchData();
  }

  async _fetchData() {
    const articles = await DatavaseApi.listDatavaseArticles({ perPage: 5, page: 1 });
    const questions = await DatavaseApi.fetchQuestionBoardsTimeline({ perPage: 5, page: 1 });
    this.setState({
      articles: articles.data,
        questions: questions.data,
      hasLoaded: true,
    });
  }

  // 最新レポート・限定記事
  _articleViews() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const articles = this.state.articles || [];
    const views = articles.map((article, i) => {
      if (article.primary_images.length > 0) {
        article.image = article.primary_images[0].image;
      }

      return (
        <DashboardArticlePanel article={article} key={i} />
      );
    });

    return (
      <PanelWrapper>
        <PanelTitle>{lang.proExclusiveArticles}</PanelTitle>
        <Panel>
          {this.state.hasLoaded ? views : <BlockSpinner visible />}
        </Panel>
        <PanelBtn id="panelBtn" to="/articles">
          {lang.readMoreButton}
        </PanelBtn>
      </PanelWrapper>
    );
  }

  // 新着の質問がこれ
  _questionViews() {
    const questions = this.state.questions || [];
      let cnt = 0;
      //It's not necessary because we'll do it
      //eslint-disable-next-line
    const views = questions.map((question, i) => {
        if (question.question_board_id === null) {
            cnt++;
            if (cnt > 5) {
                return null;
            }
            return (
                <DashboardQuestionPanel question={question} key={i}/>
            );
        }
    });

    return (
      <PanelWrapper>
        <PanelTitle> Latest Questions </PanelTitle>
        <Panel>
          {this.state.hasLoaded ? views : <BlockSpinner visible />}
        </Panel>
          <PanelBtn id="panelBtn" to="/qa_boards/">
              もっと質問を見る
          </PanelBtn>
      </PanelWrapper>
    );
  }

  // 最終
  render() {
    const latestArticles = this._articleViews();
    const questionArticles = this._questionViews();

    return (
      <Wrapper>
          <MetaTags>
              <meta content={articleEyeCatching} property="og:image"/>
          </MetaTags>
        <MainColumn>
          <TopContent>
              <AdsSideBarMobile/>
            <AccessRankingPanel panelTitle="VC・スタートアップ" panelTitleBottom="月間アクセス数ランキング" mode="investors" />
          </TopContent>
          <BottomContent>
            {questionArticles}
            {latestArticles}
          </BottomContent>
        </MainColumn>
        <SideColumn>
            <AdsSideBar/>
          <SidePanel
            mode="investors"
          />
          <SidePanel
            mode="companies"
          />
        </SideColumn>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.between("medium", "large")`
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
  ${media.lessThan("medium")`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const MainColumn = styled.div`
  width: 65%;
  ${media.between("medium", "large")`
    width: 100%;
  `}
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const SideColumn = styled.div`
  width: 30%;
  ${media.between("medium", "large")`
    width: 100% !important;
  `}
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const TopContent = styled.div`
  margin: 0 0 32px;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.between("medium", "large")`
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
  ${media.lessThan("medium")`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const PanelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 48%;
  ${media.between("medium", "large")`
    max-width: 100%;
    width: 100%;
    margin-bottom: 48px;
  `}
  ${media.lessThan("medium")`
    max-width: 100%;
    width: 100%;
    margin-bottom: 48px;
  `}
`;

const PanelTitle = styled.p`
  margin-bottom: 20px;
  color: ${color.black87};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const Panel = styled.div`
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  overflow: hidden;
`;

const PanelBtn = styled(Link)`
  display: block;
  margin: 24px 0 0 0;
  padding: 12px 48px;
  border: 2px solid ${color.primary};
  border-radius: 8px;
  color: ${color.primary};
  font-size: 18px;
  font-weight: bold;
  transition: 0.4s;
  &:hover {
    background: ${color.primary};
    color: #FFF;
    transition: 0.4s;
  }
`;
