import axios from 'axios';
import store from 'store';
import React from 'react';
import ReactGA from 'react-ga';
import {Provider} from 'react-redux';
//import { createBrowserHistory, createMemoryHistory } from 'history';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as sharedResourcesActions from './actions/shared-resources';
import * as ENV from './constants/environment';

import App from './app.js';
import TermsAndPrivacyPage from './components/pages/terms-and-privacy-page';
import SpecifiedCommercialTransactionLawPage from './components/pages/specified-commercial-transaction-law-page';
import SignupPage from './components/pages/signup-page';
import LoginPage from './components/pages/login-page';
import ResendConfirmationRequestPage from './components/pages/resend-confirmation-request-page';
import PasswordResetRequestPage from './components/pages/password-reset-request-page';
import PasswordResetPage from './components/pages/password-reset-page';
import UpgradePage from './components/pages/upgrade-page';
import DashboardPane from './components/pages/dashboard-pane';
import LatestNewsPane from './components/latest-news-pane';
import AccountSettingsPane from './components/account-settings-pane';
import ListsPane from './components/lists-pane';
import ListDetailsPane from './components/list-details-pane';
import CompaniesPane from './components/pages/companies-pane';
import CompanyDetailsPane from './components/company-details-pane';
import CompanyReviewPane from './components/company-review-pane';
import CompanyHistoryPane from './components/company-history-pane';
import CompanyRecruitsPane from './components/company-recruits-pane';
import CompanyEditsPane from './components/company-edits-pane';
import InvestorsPane from './components/pages/investors-pane';
import InvestorDetailsPane from './components/investor-details-pane';
import InvestorReviewPane from './components/investor-review-pane';
import InvestorHistoryPane from './components/investor-history-pane';
import InvestorEditsPane from './components/investor-edits-pane';
import InvestorRecruitsPane from './components/investor-recruits-pane';
import InvestorQasPane from './components/investor-qas-pane';
import PersonReviewDetails from './components/person-review-details-pane';
import MyPagePublicPane from './components/my-page-public-pane';
import RecruitsPane from './components/pages/recruits-pane';
//import SchoolsPane                from './components/schools-pane';
//import SchoolDetailsPane          from './components/school-details-pane';
import FeaturesPage from "./components/pages/features-page";
import RedirectToLandingPage from "./components/pages/redirect-to-landing-page";
import CompaniesDetailsPane from './components/companies-details-pane'
import InvestorsDetailsPane from './components/investors-details-pane'
import PeoplePane from './components/people-pane';
import PersonDetailsPane from './components/person-details-pane';
import ProductsPane from './components/products-pane';
import ProductDetailsPane from './components/product-details-pane';
import EventsPane from './components/events-pane';
//import EventDetailsPane from './components/event-details-pane';
import DatavaseArticlesPane from './components/pages/datavase-articles-pane';
import DatavaseArticleDetailsPane from './components/datavase-article-details-pane';
import SharedNewsArticlePane from './components/shared-news-article-pane';
import Error404Page from './components/pages/error-404-page';
import MyPagePane from './components/my-page-pane';
import createFinalStore from './store';
import QuestionBoardsPane from './components/pages/question-boards-pane';
import QuestionBoardDetailsPane from './components/question-board-details-pane';
import './css/index.css';
import './css/defaultTheme.scss'; // reset css
import ActionDonePage from "./components/pages/action-done-page";
import StudentSignupPage from "./components/pages/student-signup-page";

// Google Analytics
ReactGA.initialize('UA-123186157-1');

const reduxStore = createFinalStore();

// axios initialization
axios.interceptors.response.use(
  (res) => {
    if (res.headers['user-status-type']) {
      store.set('user-status-type', res.headers['user-status-type']);
    }
    if (res.headers['user-id']) {
      store.set('user-id', res.headers['user-id']);
    }
    return res;
  },
  (res) => {
    if (res.response !== undefined && res.response !== null) {
      if (res.response.status === 401 && window.location.pathname !== '/login') {
        reduxStore.dispatch(sharedResourcesActions.addUnauthorizedErrorMessage());
      } else if (res.response.status === 403) {
        reduxStore.dispatch(sharedResourcesActions.addForbiddenErrorMessage());
      } else if ([500, 502, 503].includes(res.response.status)) {
        reduxStore.dispatch(sharedResourcesActions.addServerErrorMessage());
      }
      return Promise.reject(res);
    }
  }
);

axios.interceptors.request.use(
  (config) => {
    if (!config.headers['access-token']) {
      config.headers['access-token'] = store.get('access-token');
    }
    if (!config.headers['uid']) {
      config.headers['uid'] = store.get('uid');
    }
    if (!config.headers['client']) {
      config.headers['client'] = store.get('client');
    }
    config.headers['locale'] = store.get('langCode');
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

function onRouterUpdate() {
  if (ENV.ENVIRONMENT === 'production') {
    const page = window.location.pathname;
    const url = window.location.href;
    const lastUrl = reduxStore.getState().sharedResources.url;
    if (url === lastUrl) {
      return;
    }
    reduxStore.dispatch(sharedResourcesActions.setUrl(url));
    ReactGA.set({
      user_id: store.get("user-id"),
      location: url,
      page,
    });
    ReactGA.pageview(page);
  }
}

export default (
  <Provider store={reduxStore}>
    <Router onUpdate={onRouterUpdate}>
      <Switch>
        <Route path="/action_done/:type"><ActionDonePage/></Route>
        <Route path="/account_settings"><App><AccountSettingsPane /></App></Route>
        <Route path="/articles/:id"><App><DatavaseArticleDetailsPane/></App></Route>
        <Route path="/articles"><App><DatavaseArticlesPane/></App></Route>
        <Route path="/companies/:id/details"><App><CompanyDetailsPane/></App></Route>
        <Route path="/companies/:id/recruits"><App><CompanyRecruitsPane/></App></Route>
        <Route path="/companies/:id/review/:review_id"><App><CompaniesDetailsPane/></App></Route>
        <Route path="/companies/:id/review"><App><CompanyReviewPane/></App></Route>
        <Route path="/companies/:id/history"><App><CompanyHistoryPane/></App></Route>
        <Route path="/companies/:id/edits"><App><CompanyEditsPane/></App></Route>
        <Route path="/companies/:id"><App><CompanyReviewPane/></App></Route>
        <Route path="/companies"><App><CompaniesPane/></App></Route>
        <Route path="/events/:id"><App><CompanyReviewPane/></App></Route>
        <Route path="/events"><App><EventsPane/></App></Route>
        <Route path="/features"><FeaturesPage/></Route>
        <Route path="/investors/:id/details"><App><InvestorDetailsPane/></App></Route>
        <Route path="/investors/:id/review/:review_id"><App><InvestorsDetailsPane/></App></Route>
        <Route path="/investors/:id/review"><App><InvestorReviewPane/></App></Route>
        <Route path="/investors/:id/history"><App><InvestorHistoryPane/></App></Route>
        <Route path="/investors/:id/edits"><App><InvestorEditsPane/></App></Route>
        <Route path="/investors/:id/qas"><App><InvestorQasPane/></App></Route>
        <Route path="/investors/:id/recruits"><App><InvestorRecruitsPane/></App></Route>
        <Route path="/investors/:id"><App><InvestorReviewPane/></App></Route>
        <Route path="/investors"><App><InvestorsPane/></App></Route>
        <Route path="/landing"><RedirectToLandingPage/></Route>
        <Route path="/lists/:id"><App><ListDetailsPane/></App></Route>
        <Route path="/lists"><App><ListsPane/></App></Route>
        <Route path="/login"><LoginPage/></Route>
        <Route path="/mylists"><App><ListsPane/></App></Route>
        <Route path="/mypage/:id"><App><MyPagePublicPane/></App></Route>
        <Route path="/mypage"><App><MyPagePane/></App></Route>
        <Route path="/news/:id"><App><SharedNewsArticlePane/></App></Route>
        <Route path="/news"><App><LatestNewsPane/></App></Route>
        <Route path="/password_reset_request"><PasswordResetRequestPage/></Route>
        <Route path="/people/:id/review/:review_id"><App><PersonReviewDetails/></App></Route>
        <Route path="/people/:id"><App><PersonDetailsPane/></App></Route>
        <Route path="/people"><App><PeoplePane/></App></Route>
        <Route path="/products/:id"><App><ProductDetailsPane/></App></Route>
        <Route path="/products"><App><ProductsPane/></App></Route>
        <Route path="/qa_boards/:id"><App><QuestionBoardDetailsPane/></App></Route>
        <Route path="/qa_boards"><App><QuestionBoardsPane/></App></Route>
        <Route path="/recruits/"><App><RecruitsPane/></App></Route>
        <Route path="/reset_password"><PasswordResetPage/></Route>
        <Route path="/resend_confirmation_request"><ResendConfirmationRequestPage/></Route>
        <Route path="/specified_commercial_transaction_law"><SpecifiedCommercialTransactionLawPage/></Route>
        <Route path="/signup/student"><StudentSignupPage/></Route>
        <Route path="/signup"><SignupPage/></Route>
        <Route path="/terms_and_privacy"><TermsAndPrivacyPage/></Route>
        <Route path="/upgrade"><UpgradePage/></Route>
        <Route path="/"><App><DashboardPane/></App></Route>
        <Route><Error404Page/></Route>
      </Switch>
    </Router>
  </Provider>
);
